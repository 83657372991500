import React from 'react';

const Footer = () => {
    return (
        <div className='mt-5'>
            <div className='border-t-2 text-center helvetica py-4'>Copyright © Egenie. All rights reserved.</div>
        </div>
    );
}

export default Footer;